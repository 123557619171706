<template functionnal>
  <div class="template">
    <div class="template__content">
      <div class="row">
        <div class="col-12">
          <h1>Contact</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6 class="mb-4">Notre adresse</h6>

          <div>
            <strong>FEMMES INITIATIVES</strong><br />
            7 Rue Lachelier<br />
            75013 PARIS<br />
            <strong
              >Nos locaux ne bénéficient pas d'un accès aux personnes handicapées et PMR. Néanmoins une
              réferente handicap est à votre disposition pour vous renseigner.</strong
            >
            <br /><br />
            <strong>Métro/Tram : </strong>Porte de Choisy (Ligne 7/Tram 3)<br />
            <strong>Nos horaires d’ouverture : </strong>Lundi au Vendredi de 09H à 12H30 et de 14H à
            17H00<br /><br />
            <strong
              >Si nous ne sommes pas joignables, contactez-nous par mail ou laissez-nous un message
              sur nos téléphones portables. Nous vous répondrons sous une semaine maximum. Merci !
            </strong>
            <br /><br />
            <div class="card mr-6 mb-10">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2627.012801811142!2d2.363690415673014!3d48.81981717928377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6722a3ae56aa3%3A0x6e9698652ca1f87f!2s7%20Rue%20Lachelier%2C%2075013%20Paris!5e0!3m2!1sen!2sfr!4v1600275314225!5m2!1sen!2sfr"
                width="100%"
                height="650"
                frameborder="0"
                style="border: 0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              >
              </iframe>
            </div>
          </div>
        </div>

        <div class="col-6 u-text-center">
          <h6 class="mb-4">Nous contacter</h6>

          <div class="mb-10 u-text-center">
            <figure class="avatar avatar--xlarge">
              <img src="@/assets/images/elisette.png" />
            </figure>
            <br />
            <strong>Elisette ABADA</strong><br />
            Directrice<br />
            <strong>Téléphone : </strong>01 44 23 74 33<br />
            <strong>E-mail : </strong>femmes.initiatives@free.fr<br />
          </div>

          <div class="mb-10 u-text-center">
            <figure class="avatar avatar--xlarge"><img src="@/assets/images/miriam.png" /></figure>
            <br />
            <strong>Miriam MAMMERI</strong><br />
            Chargée de formation<br />
            <strong>Téléphone : </strong>07 68 07 20 69<br />
            <strong>E-mail : </strong>femmes.initiatives@free.fr<br />
            <strong>E-mail : </strong>femmes.initiatives@gmail.com<br />
          </div>

          <div class="mb-10 u-text-center">
            <figure class="avatar avatar--xlarge"><img src="@/assets/images/samia.png" /></figure>
            <br />
            <strong>Samia BEN JERAD</strong><br />
            Médiatrice socioculturelle<br />
            <strong>Téléphone : </strong>07 49 33 75 13<br />
            <strong>E-mail : </strong>mediation.femmesinitiatives@gmail.com<br />
            <strong>E-mail : </strong>j.femmesinitiatives@gmail.com<br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
